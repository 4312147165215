hr.divider {
  width: 14vw;
  height: 3px;
  border-radius: 20px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

@media (max-width: 850px) {
  hr.divider {
    width: 26vw;
  }
}
