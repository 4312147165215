.wedding-countdown {
    background-color: var(--counter-bg);
    padding-inline: 10vw;
}

.wedding-countdown .tile{
    margin: 2.5vw 0.5vw 3vw;
    padding: 2.5vw 2vw;
    border-radius: 3vw;
    background-color: var(--counter-tile-bg);
}

.wedding-countdown .tile .MuiTypography-root{
    font-size: 4vw;
}

.wedding-countdown .tile .unit{
    margin-bottom: -5vw;
}

@media (max-width: 850px) {
    .wedding-countdown {
        padding-inline: 2vw;
    }
    .wedding-countdown .tile .MuiTypography-root{
        font-size: 5vw;
    }
    .wedding-countdown .tile .MuiTypography-root.value{
        font-size: 6.5vw;
    }
    .wedding-countdown .tile{
        margin: 3vw 1vw 4vw;
        padding: 2vw 1vw;
    }
}


