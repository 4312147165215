.photos.picture {
    background-image: url("../../public/assets/image/cortal-gran/CORTAL4.jpg");
    background-size: cover;
    background-position: center;
    min-height: 350px;
}

.tabs {
    margin: auto;
}
