.presentation {
    background-image: url("../../../public/assets/image/fondo.png");
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    /*background-size:100% 100%;*/
}


@media (max-width: 850px) {
    .presentation {
        min-height: 100vw;
    }
}
