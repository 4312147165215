:root {
  --defaul-color: black;
  --primary-color: #EABD98;
  --secondary-color: black;
  --counter-bg: #EABD98;
  --counter-tile-bg: #DDA891;
  --max-window-size: 1148px;
  --text-line-heigth: 24px;
  --button-red: red;
  --button-green: green;
}

a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

.primary-bg-color {
  background-color: var(--primary-color);
}
.primary-color {
  color: var(--primary-color);
}
.secondary-color {
  color: var(--secondary-color);
}
.secondary-bg-color {
  background-color: var(--secondary-color);
}

@font-face {
  font-family: "Janeth Ville";
  src: url("../public/assets/fonts/janethville/Janethville.ttf");
}
.janeth-ville {
  font-family: 'Janeth Ville', sans-serif;
}

h1 {
  font-weight: 300;
}

html, body {
  height: 100%;
  margin: 0px;
  font-family: 'Times New Roman', sans-serif;
  font-weight: 300;
  /*letter-spacing: 0.1rem;*/
  color: var(--defaul-color);
}

.row {
  display: flex;
}
.column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

.third {
  width: 33%;
}
.fourth {
  width: 25%;
}
.half {
  width: 50%;
}
.fill{
  width: 100%;
}


.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.text-xxl{
  font-size: 36px;
}
.text-xl{
  font-size: 26px;
}
.text-l{
  font-size: 22px;
}
.text-m{
  font-size: 16px;
}
.text-s{
  font-size: 14px;
}
.text-xs{
  font-size: 10px;
}

.section.MuiContainer-root {
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (max-width: 450px) {
  .section.MuiContainer-root {
    padding-top: 60px;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 32px;
}

.modal.fullscreen{
  height: 100%;
}

.modal.message {
  background-color: var(--primary-color);
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

button.red svg {
  background-color: var(--button-red);
}
button.green svg {
  background-color: var(--button-green);
}

@media (max-width: 850px) {
  .mobile-third {
    width: 33%;
  }
  .mobile-half {
    width: 50%;
  }
  .mobile-fill {
    width: 100%;
  }
}
