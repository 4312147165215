nav .title{
    margin-top: 3vw;
}

@media (min-width: 850px) {
    nav svg.MuiSvgIcon-root {
        width: 3vw;
        height: 3vw;
    }
}

@media (max-width: 450px) {
    nav .title{
        margin-top: 15vw;
    }
}
